import React, { useEffect, useState } from 'react';
import axiosInstance from '../../components/AxiosInstance';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import {config} from '../../config/config.js';
import { useAuth } from "../../components/AuthProvider";
import Menu from "../../components/Menu";
import TopBar from "../../components/TopBar";
import header_progress_svg from '../../assets/img/header_progress.svg';
import filter_svg from '../../assets/img/filter.svg';
import arrow_down_svg from '../../assets/img/arrow_down.svg';
import ReactPaginate from 'react-paginate';



export default function EstimatesPage() {

  const navigate = useNavigate();

   const [estimates, setEstimates] = useState([]);
   const [isLoading, setisLoading]=useState(true);
   const [isError, setisError] = useState(false);

  useEffect(() => {
    setisLoading(true);
// console.log('token', axios.defaults.headers.common["Authorization"])
   axiosInstance.get("/base/estimates/")
    .then(function(response){

      setEstimates(response.data)
      setisLoading(false)
    })
    .catch((err) => {
        setisError(true);
        setisLoading(false);
      });
  }, [])

  const handleEstimate = (e) => {
       navigate(`/estimate/${e.currentTarget.getAttribute('estimate_id')}/`);
    }

const handlePageClick = (e) => {
   
    setisLoading(true)

   axiosInstance.get("/base/estimates/?page="+(e.selected+1))
    .then(function(response){

      setEstimates(response.data)
      setisLoading(false)
    })
    .catch((err) => {
        setisError(true);
        setisLoading(false);
      });
  }


  return (  
    <>
    <Menu/>
    <div id="page">
    <TopBar/>
    <div className="container">
      <div className="header">Накопительные</div>
        <div className="block">
          

          <div className="table nh">
              <div className="table_header">
                <div className="table_cell" style={{width:'250px'}}>
                  Номер сметы
                </div>
                <div className="table_cell" style={{width:'250px'}}>
                  Договор
                </div>
                <div className="table_cell">
                  ДС
                </div>
                <div className="table_cell">
                  Проект, Объект
                </div>
                <div className="table_cell table_right table_cost">
                  Стоимость материалов
                </div>
                <div className="table_cell table_right table_cost">
                  Цена работ
                </div>
                <div className="table_cell table_right table_cost">
                  Итого
                </div>
              </div>
              {isLoading? 
                <>
                <div className="table_row"><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div></div>
                <div className="table_row"><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div></div>
                <div className="table_row"><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div></div>
                 </>
              :
                <>
                {estimates.count>0?
                <>
                {estimates.count>0 && estimates.estimates.map((estimate) => ( 
      
                <div className="table_row" key={estimate.id} relevance={estimate.relevance?'true':'false'}>
                    <div className="table_cell">
                      <Link to={`/estimate/${estimate.id}/`}>
                        <div class="link">{estimate.number}</div>
                        <div class="small_info">{new Date(estimate.date).toLocaleDateString()}</div>
                      </Link>
                    </div>
                    <div className="table_cell">
                      <Link to={`/deal/${estimate.deal_id}/`}>
                        <div class="link">{estimate.deal_name}</div>
                        <div class="small_info">{estimate.deal_date}</div>
                      </Link>
                    </div>
                    <div className="table_cell">
                      {estimate.agreement_id?
                        <>
                        <div class="link">{estimate.agreement_name}</div>
                        <div class="small_info">{estimate.agreement_date}</div>
                        </>
                      :null}
                    </div>
                    <div className="table_cell">
                      <Link to={`/project/${estimate.project_id}/`}>
                        <div class="link">{estimate.project}</div>
                      </Link>
                      <Link to={`/object/${estimate.object_id}/`}>
                        <div class="link">{estimate.object}</div>
                      </Link>
                    </div>
                    <div className="table_cell table_right">
                      {estimate.money.length && estimate.money.map((money) => (
                          <div>{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(money.cost_materials)} {money.currency?config.currency_code[money.currency].code:null}</div>
                        ))}
                    </div>
                    <div className="table_cell table_right">
                      {estimate.money.length && estimate.money.map((money) => (
                          <div>{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(money.cost_installation)} {money.currency?config.currency_code[money.currency].code:null}</div>
                        ))}
                    </div>
                    <div className="table_cell table_right">
                      {estimate.money.length && estimate.money.map((money) => (
                          <div>{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(money.cost_total)} {money.currency?config.currency_code[money.currency].code:null}</div>
                        ))}
                    </div>
                    
                  </div>

              ))}
                </>
                  :
                  <div className="table_row"><div className="table_cell">Сметы отсутствуют</div></div>
                }
                </>
              }
           </div>
        </div>
        
        {!isLoading && estimates.page_count>1? 
        <ReactPaginate
        breakLabel="..."
        nextLabel="Вперед"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        forcePage={estimates.page-1}
        pageCount={estimates.page_count}
        previousLabel="Назад"
        renderOnZeroPageCount={null}
      /> :null}
      
      </div>

    </div>
    </>
  );

}

