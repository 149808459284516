import React, {useState, useEffect} from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { useAuth } from "../../components/AuthProvider";

import axios from 'axios'
import {config} from '../../config/config.js';
import logo_svg from '../../assets/img/logo.svg';
export default function KeycloakPage() {

    const [queryParameters] = useSearchParams()
    const [error, setError]=useState('');

    
    useEffect(() => {
        const state=sessionStorage.getItem("state")
        if( state && queryParameters.get("state")==state) {

            axios.post("/api/keycloak/", {
              'redirect_uri': config.keycloak.redirect_uri,
              'code': queryParameters.get("code")
            })
            .then(function (response) {
                if(response.status === 200){
                    
                 
                    localStorage.setItem('access', response.data.access);
                    localStorage.setItem('refresh', response.data.refresh);
                    localStorage.setItem('email', response.data.email);
                    localStorage.setItem('keycloak', true);
                    if(response.data.superadmin)
                        localStorage.setItem('superadmin', response.data.superadmin);

                    window.location.href = '/admin/subs/';
                }
            })
            .catch(function (error) {
              
                setError(error.data)

            });

        } else {
            setError('Ошибка входа')
            sessionStorage.removeItem("state")
        }

        
    })
    

  return (
    <>  
        
        <div className="login_block">
        <div className="login_header"><img src={logo_svg} alt=""/>Вход</div>
         {error==''?<div className="loader"><div className="loader_row"><div></div><div></div><div></div><div></div></div></div>:
            <>
                <div className="error">
                    {error}
                </div>
                <div className="text">
                    Вы можете перейти на <Link to='/login/'>страницу входа</Link>
                </div>
            </>
            }
      </div>
    </>
  );
}