import React, { useEffect, useState } from 'react';
import axiosInstance from '../../components/AxiosInstance';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import {config} from '../../config/config.js';
import { useAuth } from "../../components/AuthProvider";
import Menu from "../../components/Menu";
import TopBar from "../../components/TopBar";

import delete_svg from '../../assets/img/delete.svg';
import small_loader_svg from '../../assets/img/small_loader.svg';


export default function EstimatePage() {
  const { id } = useParams()
  const navigate = useNavigate();
  const [tableRow, setTableRow] = useState(0);
   const [report, setReport] = useState([]);
    const [files, setFiles] = useState([]);
   const [isLoading, setisLoading]=useState(true);
   const [isError, setisError] = useState([]);
   const [isSuccess, setisSuccess] = useState([]);

   // console.log((new Date()));
/*
  useEffect(() => {
    setisLoading(true);
// console.log('token', axios.defaults.headers.common["Authorization"])
   axiosInstance.get("/base/estimates/")
    .then(function(response){

      setEstimates(response.data)
      setisLoading(false)
    })
    .catch((err) => {
        setisError(true);
        setisLoading(false);
      });
  }, [])*/


const handleAddFiles = (e) => {


      document.querySelector('.upload_files input').click()
  }
   const handleDownload = (e) => {
    let file_id=e.target.parentNode.getAttribute("file_id")

    axiosInstance.get(`/base/report/file/${file_id}/`, {
        responseType: 'arraybuffer'
    }).then(function(response){

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', response.headers['content-disposition'].split('filename=')[1]);
        document.body.appendChild(link);
        link.click();

 
    })
    .catch((err) => {
 
      });
     
  }

  const handleDelete = (e) => {
    let file_id=parseInt(e.target.parentNode.parentNode.getAttribute("file_id"))

    axiosInstance.delete(`/base/report/file/${file_id}/`, {
        responseType: 'arraybuffer'
    }).then(function(response){

       setFiles(files.filter(item => item.id !== file_id))
    })
    .catch((err) => {
        alert(err)
      });
     
  }

   const handleFilesChange = (e) => {
   

    var formData = new FormData();
    // var imagefile = document.querySelector('#file');
    
    let files_array=[]
    for (var i = 0; i < e.target.files.length; i++) {
 
      formData.append("files", e.target.files[i]);

      files.push({'loading':true, 'name': e.target.files[i].name})
    }

    formData.append("report", id);
    

    setFiles([...files])
    document.querySelector('.upload_files input').value = "";

    axiosInstance.post('/base/report/file/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    }).then(function(response){
                   
                   let newFiles=files.filter(item => !item.loading)
                   newFiles=newFiles.concat(response.data.files)
                   setFiles(newFiles)
                    
                    // setEstimate([...estimate, {}])
                    // console.log(response.data)
                   
                    setisLoading(false)
                  })
                  .catch((err) => {
        
                    setisLoading(false);
                    });


    }
   const handleInputCheck = (e) => {
      if(e.key!='Tab' && e.key!='Enter') {

        let value=parseFloat(e.target.value.replace(",", '.'))
        /*
        let nullvalue=false
        if(value==-0) nullvalue=true
      
        let firstminus=false
        if(e.target.value.slice(0,1)=='-')
          firstminus=true

        

        let lastdot=false
        if(e.target.value.replace(",", '.').slice(-1)=='.')
          lastdot=true

        if(value!=0 && !value) value=''
        if(lastdot) {

          e.target.value=value.toString().replace(".", ',')+','
        }
        else {
          if(firstminus && value=='' && !nullvalue) 
          
          e.target.value=value.toString().replace(".", ',')
        }*/
  /*
        if(firstminus && value=='' && !nullvalue) 
         e.target.value='-'
        else if(firstminus && nullvalue && !lastdot) 
         e.target.value='-0'*/
        
        if(!value) value=0
        let max=parseFloat(e.target.parentNode.parentNode.nextSibling.innerText.replace(" ", '').replace(/\u00a0/g, '').replace(" ", '').replace(",", '.'))
        /*let value=parseFloat(e.target.value.replace(",", '.').replace(/[^.\d]/g,''))
        let lastdot=false
        if(e.target.value.replace(",", '.').slice(-1)=='.')
          lastdot=true

        if(!value) value=''
        if(lastdot)
          e.target.value=value.toString().replace(".", ',')+','
        else
          e.target.value=value.toString().replace(".", ',')

        let max=parseFloat(e.target.parentNode.parentNode.nextSibling.innerText.replace(" ", '').replace(",", '.'))*/

        if(value<0 || value<=max) {
          e.target.parentNode.classList.remove("error");
          //перерасчёт итогов

          let work_id=e.target.parentNode.parentNode.parentNode.getAttribute("work_id")

           report.works.forEach((work) =>{
              if(work.work_estimate==work_id) {
               

               
               Object.keys(report.total).forEach((currency, i) =>{
                 if(work.money[currency]) {
                  if(work.money[currency]['cost_materials_per_unit'])
                    document.querySelectorAll("#table tr[work_id='"+work_id+"'] td")[9+5*i].innerText=Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(work.money[currency]['cost_materials_per_unit']*value)
                  if(work.money[currency]['cost_installation_per_unit'])
                    document.querySelectorAll("#table tr[work_id='"+work_id+"'] td")[11+5*i].innerText=Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(work.money[currency]['cost_installation_per_unit']*value)
                  if(work.money[currency]['cost_materials_per_unit'] || work.money[currency]['cost_installation_per_unit'])
                    document.querySelectorAll("#table tr[work_id='"+work_id+"'] td")[12+5*i].innerText=Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format((work.money[currency]['cost_materials_per_unit']+work.money[currency]['cost_installation_per_unit'])*value)
                   }
                })
              
              }

            })
          
        } else {
          e.target.parentNode.classList.add("error");
        }
        //пересчёт итогов
        Object.keys(report.total).forEach((currency, i) =>{
          let new_total=0
          document.querySelectorAll("#table tr[work_id] td:nth-child("+(13+5*i)+")").forEach((total, i) =>{
            if(new_total)
              new_total=new_total+parseFloat(total.innerText.replace(/\s+/g, '').replace(",", '.'))
            // console.log(total.innerText.replace(/\s+/g, '').replace(",", '.'), i)
          })
          document.querySelector("#total td:nth-child("+(6+5*i)+") b").innerText=Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(new_total)
          if(report.tax_value!='Без НДС') {


            document.querySelector("#total_tax td:nth-child("+(6+5*i)+") b").innerText=Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(new_total*parseInt(report.tax_value)/100)
            document.querySelector("#total_full td:nth-child("+(6+5*i)+") b").innerText=Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(new_total*(parseInt(report.tax_value)+100)/100)
          }
        })
      }
   }
   const handleInput = (e) => {


      if(e.key=='Tab' || e.key=='Enter') {
        let value=parseFloat(e.target.value.replace(",", '.'))
        // let max=parseFloat(e.target.parentNode.parentNode.nextSibling.innerText.innerText.replace(" ", '').replace(",", '.'))
        let max=parseFloat(e.target.parentNode.parentNode.nextSibling.innerText.replace(" ", '').replace(/\u00a0/g, '').replace(",", '.'))
        if(value>0 && value>max ) {
            e.target.parentNode.classList.add("error");

        } else if(value!=0) {


          e.target.parentNode.classList.remove("error");
          e.target.value=value.toString().replace(".", ',')


        } else {
          e.target.value=''
        }
      } 
      
      // e.target.previousSibling.disabled = true;
      // e.target.style.display = 'none';
      // e.target.nextSibling.style.display = 'inline-block';
      // e.target.previousSibling.parentNode.classList.add("saved");
   }

/*

   const handleEdit = (e) => {
      e.target.previousSibling.previousSibling.disabled = false;
      e.target.previousSibling.previousSibling.focus();


      let estimate=parseInt(e.target.parentNode.parentNode.parentNode.parentNode.parentNode.getAttribute("estimate_id"))
      let work=parseInt(e.target.parentNode.parentNode.parentNode.getAttribute("work_id"))
      axiosInstance.post('/base/report/', {'estimate':estimate, 'work':work, 'workload':0}).then(function (response) {
          if(response.status === 200){
            console.log('remove report')
          }
      })
      .catch(function (error) {
        console.log(error)
      });

   }*/
   const handleFile = (e) => {
   
     setisLoading(true);

    
     axiosInstance.get(`/base/report/${id}/file/`, {
        responseType: 'arraybuffer'
    })
    .then(function(response){
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Отчёт о выполнении № '+id+' от '+ new Date(report.date).toLocaleDateString()+'.xlsx');
        document.body.appendChild(link);
        link.click();
        // console.log(response.data)
      // console.log(response.data)

      // setReport(response.data)
      // setTableRow(2+Object.keys(response.data['total']).length*5)
      setisLoading(false)
    })
    .catch((err) => {
      setisError(true);
      setisLoading(false);
      });

   }

   const handleRemove = (e) => {
    if(window.confirm(`Вы хотите удалить выполнение ${id}?`)) {

   	 axiosInstance.delete(`/base/report/${id}`).then(function (response) {
                if(response.status === 200){
                  navigate(-1)
                  setisLoading(false);
                }
            })
            .catch(function (error) {
              alert(error)
              setisLoading(false);
            });
    }
   }
   const handleSend = (e) => {

     
    let data={}
     let error_text=[]
     let error_input=false
     let date=document.getElementById('date').value
     let date_from=document.getElementById('date_from').value
     let date_to=document.getElementById('date_to').value

     let inputs=document.querySelectorAll("#table input");

     inputs.forEach((input) =>{
      if(!error_input) {
        let value=parseFloat(input.value.replace(",", '.'))
        if(value) {
          let work=parseInt(input.parentNode.parentNode.parentNode.getAttribute('work_id'))
          let max=parseFloat(input.parentNode.parentNode.nextSibling.innerText.replace(" ", '').replace(/\u00a0/g, '').replace(",", '.'))

          if(value>0 && value>max) error_input=true
          console.log(id, error_input, work, value, max)
          data[work]=value
        }
      }
      
      

      // console.log(input.parentNode.parentNode.nextSibling.innerText)
      
     });

     if(Object.keys(data).length==0) {
      error_text.push('Укажите объём выполненных работ')
     }

     if(error_input) {
      error_text.push('Неверный объём работ')
     }

     if(!date) {
      error_text.push('Неверная дата')
     }
     if(!date_from) {
      error_text.push('Неверная начальная дата')
     }
     if(!date_to) {
      error_text.push('Неверная конечная дата')
     }

     if(date_from && date_to){
      if(new Date(date_from)>new Date(date_to)) {
        error_text.push('Дата начала не может быть больше даты конца')
      }
     }
     if(error_text){
      setisError(error_text)
     }

     if(error_text.length==0) {
       setisLoading(true);
       setisSuccess([])
       axiosInstance.post('/base/report/', {'report':parseInt(id), 'date':date, 'date_from':date_from, 'date_to':date_to, 'data':data}).then(function (response) {
                if(response.status === 200){
                	setisSuccess(['Отредактировано'])
                  report.back=false
                  console.log('save report')
                  setisLoading(false);
                }
            })
            .catch(function (error) {
              alert(error)
              setisLoading(false);
            });


     }
     
     
      // alert('отправка')

   }

  useEffect(() => {
    setisLoading(true);

    
     axiosInstance.get(`/base/report/${id}`)
    .then(function(response){
      

      console.log(response.data)

      setReport(response.data)
      setFiles(response.data.files)
      setTableRow(2+Object.keys(response.data['total']).length*5)
      setisLoading(false)
    })
    .catch((err) => {
      setisError(true);
      setisLoading(false);
      });
    }, [])



  return (  
    <>
    <Menu/>
    <div id="page">
    <TopBar/>
    <div className="container">
      
      {report.id?
        <>
          <div className="header">Отчёт о выполнении {report.id} от {new Date(report.date).toLocaleDateString()}</div>

        

          <div className="block">
           <div className="block_table">

            <table className="list" id="table" estimate_id={report.estimate_id}>
            <thead>
              <tr>
                 
                <th>Наименование работ</th>
                <th>ID</th>
               
                <th>{report.accept?'Внесено':'Внести'}</th>

                {!report.accept?
                  <>
                  <th>Остаток</th>
                  <th>На утверждении</th>
                  <th>Кол-во, закрытое КС-2 (накопление)</th>
                  <th>Кол-во</th>
                  </>
                :null
                }
                  <th>Ед.изм.</th>
                  
                  {report.total && Object.keys(report.total).map((currency) => (  
                    <>
                    <th>Цена материала за ед., {currency!=='null'?currency:'Валюта'}{!report.tax?', без НДС':null}</th>
                    <th>Стоимость материала, {currency!=='null'?currency:'Валюта'}{!report.tax?', без НДС':null}</th>
                    <th>Цена работ за ед., {currency!=='null'?currency:'Валюта'}{!report.tax?', без НДС':null}</th>
                    <th>Стоимость работ, {currency!=='null'?currency:'Валюта'}{!report.tax?', без НДС':null}</th>
                    <th>Стоимость Итого, {currency!=='null'?currency:'Валюта'}{!report.tax?', без НДС':null}</th>
                    </>
                  ))}
              </tr>

              
               </thead>       
               <tbody>

                {report.works && report.works.map((work) => (  
                  <>
                    
                     {work.section ?
              <tr className="section show" level={work.level} key={work.number}>
                <td>{work.name}</td>
                <td>{work.number}</td>
                <td></td>

                {!report.accept?
                  <>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                </>:null}
                <td></td>
                {report.total && Object.keys(report.total).map((currency) => ( 
                <>
                <td className="right"></td>
                <td className="right"></td>
                <td className="right"></td>
                <td className="right"></td>
                <td className="right"></td>
                </>
              ))}
                


               
                
          
              </tr>
             :
              <tr className="show"  work_id={work.work_estimate} level={work.level} key={work.number}>
                <td>{work.name}</td>
                <td><span>{work.number}</span></td>
                
                
                <td className="center">
                
                
                  {(work.workload_report_progress!=work.workload)?
                  <>
                  {!report.accept?
                    <div className="report">
                      <input type="text" defaultValue={report.workload[work.work_estimate]} onKeyDown={handleInput} onKeyUp={handleInputCheck} />
                      <div className="text">больше остатка</div>
                    </div>
                  :
                    <>{report.workload[work.work_estimate]?Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format((report.workload[work.work_estimate]).toFixed(2)):null}</>
                  }
                  </>
                    :<>
                    {!report.accept?<div className="done">Выполнено</div>:null}
                  </>
              
                }
                </td>
                {!report.accept?
                <>
                <td className="center">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format((work.workload-work.workload_report_progress).toFixed(2))}</td>
                <td className="center report_save">{work.workload_report?<>{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(work.workload_report)}</>:null}</td>
                <td className="center">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(work.workload_progress)}</td>
                <td className="center">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(work.workload)}</td>
                </>:null}
                <td className="center">{work.unit}</td>
                  {report.total && Object.keys(report.total).map((currency) => ( 
            <>
            
              {work.money[currency] ?
                <>  
                  {work.money[currency].cost_materials_per_unit ?
                   <td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(work.money[currency].cost_materials_per_unit)}</td>
                  : 
                   <td className="right"></td>}

                   {work.money[currency].cost_materials ?
                   <td className="right">{report.workload[work.work_estimate]?Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(work.money[currency].cost_materials_per_unit*report.workload[work.work_estimate]):'0,00'}</td>
                  : 
                   <td className="right"></td>}

                   {work.money[currency].cost_installation_per_unit ?
                   <td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(work.money[currency].cost_installation_per_unit)}</td>
                  : 
                   <td className="right"></td>}

                   {work.money[currency].cost_installation ?
                   <td className="right">{report.workload[work.work_estimate]?Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(work.money[currency].cost_installation_per_unit*report.workload[work.work_estimate]):'0,00'}</td>
                  : 
                   <td className="right"></td>}

                  {work.money[currency].cost_total ?
                   <td className="right">{report.workload[work.work_estimate]?Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format((work.money[currency].cost_materials_per_unit+work.money[currency].cost_installation_per_unit)*report.workload[work.work_estimate]):'0,00'}</td>
                  : 
                   <td className="right"></td>}
                  
                </>
              :
                <><td className="right"></td>
                  <td className="right"></td>
                  <td className="right"></td>
                  <td className="right"></td>
                  <td className="right"></td></>
              
              
              
              }
                

                    
                  </>
                ))}
              </tr>
              }

              
              </>
              ))}
            
                <tr id="total" className="show" >
                  <td className="right" colSpan={report.accept?4:8}><b>Всего по смете,  без НДС:</b></td>
                  
                  {Object.keys(report.money).map((currency) => ( 
                    <>              
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="right"><b>{report.tax?
                          Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(report.money[currency].cost_total*(1-parseInt(report.tax_value)/100))
                          :Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(report.money[currency].cost_total)}</b></td>
                    </>
                  ))}
                </tr>
                {report.tax_value!='Без НДС'?
                <tr id="total_tax" className="show" >
                  <td className="right" colSpan={report.accept?4:8}><b>НДС {report.tax_value}:</b></td>
                  
                  {Object.keys(report.money).map((currency) => ( 
                    <>              
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="right"><b>{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format((report.money[currency].cost_total*parseInt(report.tax_value)/100).toFixed(2))}</b></td>
                    </>
                  ))}
                </tr>
                :null}
                {report.tax_value!='Без НДС'?
                <tr id="total_full" className="show" >
                  <td className="right" colSpan={report.accept?4:8}><b>Всего по смете,  с НДС {report.tax_value}:</b></td>
                  
                  {Object.keys(report.money).map((currency) => ( 
                    <>              
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="right"><b>{report.tax?
                      Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(report.money[currency].cost_total)
                      :Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format((report.money[currency].cost_total*(parseInt(report.tax_value)+100)/100).toFixed(2))}</b></td>
                    </>
                  ))}
                </tr>
                :null}
                </tbody>
             
            </table>


          </div>

          <div className="block_report">
           <div className="setting_info">Дата отчёта о выполнении</div>
            <div className="setting_value">
              <input id="date" type="date" min="2000-01-01" defaultValue={new Date().toISOString().split('T')[0]} defaultValue={report.date}/>
              
            </div>
            <div className="setting_info">Период отчёта о выполнении</div>
            <div className="setting_value">
              <input id="date_from" type="date" min="2000-01-01" max={new Date().toISOString().split('T')[0]} defaultValue={report.date_from}/><div className="separator">-</div>
              <input id="date_to" type="date" min="2000-01-01" max={new Date().toISOString().split('T')[0]} defaultValue={report.date_to}/>
            </div>
            
            <div className="files">
                <div className="header">Файлы</div>
                {files && files.map((file)=>(
                  <>{file.error?<div className="error" key={file.id}>Ошибка загрузки файла {file.name}</div>:null}
                    {file.loading?<div className="loading" key={file.id}><img src={small_loader_svg}/>Загружается {file.name}</div>:null}
                    {!file.loading&&!file.error?<div file_id={file.id} key={file.id}>{!report.accept&&!file.admin?<div className="delete" onClick={handleDelete}><img src={delete_svg}/></div>:null}<Link onClick={handleDownload}>{file.name}</Link></div>:null}
                    
                  
                  </>

                 
                  ))}

                {!report.accept?
                <div className="upload_files">
              <div className="button" onClick={handleAddFiles}>Добавить файлы</div>
              <input
                type="file"
                multiple={true}
                
                accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                onChange={handleFilesChange}
              />

            </div>:null}
            </div>

            {report.comment?
              <>
                <div className="setting_info">Комментарий ПК</div>
                <div className="setting_value">{report.comment}</div>
              </>
              :null
            }
            

            
            {isError.length?<div className="error">{isError.map((error)=>(<div>{error}</div>))}</div>:null}
            {isSuccess.length?<div className="success">{isSuccess.map((success)=>(<div>{success}</div>))}</div>:null}
            {isLoading?<div className="loader"><div className="loader_row"><div></div><div></div><div></div><div></div></div></div>:
            <>

            {report.back?<div className="error">Возвращён на доработку</div>:null}
            {report.accept?<div className="success">Отчёт принят</div>:null}
            {report.reject?<div className="error">Отчёт отклонён</div>:null}


            {!report.reject?<div className="buttons">{!report.accept?<div className="button" onClick={handleSend}>Отредактировать</div>:null}<div className="button" onClick={handleFile}>Распечатать</div>{!report.accept?<div className="button remove" onClick={handleRemove}>Удалить</div>:null}</div>:null}
            </>}
            

          </div>
        </div>
        </>
        :
        null
        }

      </div>

    </div>
    </>
  );

}

