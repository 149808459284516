export const config=
  {
    "domain": process.env.REACT_APP_DOMAIN,
    "currency": ['RUB', 'USD', 'EUR'],
    "currency_code": {
      'RUB':{'code':'₽'},
      'USD':{'code':'$'},
      'EUR':{'code':'€'}
    },
    "keycloak": {
      server_url: process.env.REACT_APP_KEYCLOAK_SERVER_URL,
      client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
      realm_name: process.env.REACT_APP_KEYCLOAK_REALM_NAME,
      client_secret_key: process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET_KEY,
      redirect_uri: process.env.REACT_APP_KEYCLOAK_REDIRECT_URI
    }
  }
;
