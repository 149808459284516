import axios from 'axios'
import {config} from '../config/config.js';

const axiosInstance = axios.create({
  baseURL: '//'+config.domain+'/api/',
  headers: {
    'Content-Type': 'application/json',
  }
  // other configurations
})



axiosInstance.interceptors.request.use(request => {

  const access = localStorage.getItem('access');
   // console.log('aaa', access)
  if (access) {
    request.headers['Authorization'] = `Bearer ${access}`;
  }
  const admin_name = localStorage.getItem('admin_name');
   // console.log('aaa', access)
  if (admin_name) {
    request.headers['Admin-Name'] = encodeURI(admin_name);
  }
  return request;
}, error => {
  return Promise.reject(error);
});


axiosInstance.interceptors.response.use(
  response => response, // Directly return successful responses.
  async error => {
    // console.log('keycloak', localStorage['keycloak'], JSON.parse(localStorage['keycloak'])==true)

    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      if(error.response.data.code=='user_inactive') {
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
        localStorage.removeItem('keycloak');
        localStorage.removeItem('superadmin');
        localStorage.removeItem('admin_name');
        window.location.href = '/login/';
      }
      originalRequest._retry = true; // Mark the request as retried to avoid infinite loops.
      try {
        const refreshToken = localStorage.getItem('refresh'); // Retrieve the stored refresh token.
        // Make a request to your auth server to refresh the token.
        // const REFRESH_URL='/user/refresh/'
        let refreshUrl='/api/user/refresh/'
        if(JSON.parse(localStorage['keycloak'])==true) {
          // setRefreshUrl('/keycloak/refresh/')
          refreshUrl='/api/keycloak/refresh/'
          // console.log(refreshUrl)
        }
        const response = await axios.post(refreshUrl, {
            refresh: refreshToken,
          });
          
          

        

    
        // console.log('old token', localStorage.getItem('access'));

        // Store the new access and refresh tokens.

        // console.log('new token', response.data.access)


        localStorage.setItem('access', response.data.access);
        localStorage.setItem('refresh', response.data.refresh);
        // localStorage.setItem('keycloak', false);

        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;

    

        // originalRequest.headers['Authorization'] = 'Bearer ' + response.data.access;

        // await axios.post('/user/token/blacklist/', {
        //   refresh: refreshToken,
        // });

        // axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;
        return axiosInstance(originalRequest); // Retry the original request with the new access token.
      } catch (refreshError) {
        // Handle refresh token errors by clearing stored tokens and redirecting to the login page.
        // console.error('Token refresh failed:', refreshError);
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
        localStorage.removeItem('keycloak');
        localStorage.removeItem('superadmin');
        localStorage.removeItem('admin_name');
        if(originalRequest.url!='/user/login/')
          window.location.href = '/login/';
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error); // For all other errors, return the error as is.
  }
);

export default axiosInstance
